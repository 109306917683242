@font-face{
  font-family:'SourceCodePro';
  src:url('../public/assets/fonts/SourceCodePro.ttf');
}

body {
  margin: 0;
  font-family: SourceCodePro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0E2E4E;
}
* {
  font-family: SourceCodePro, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.loading-points {
  height: 15px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-points:before, .loading-points:after, .loading-points span {
  content: '';
  border: 5px solid grey;
  background: grey;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50px;
  margin: 3px;
  animation: 0.6s fade-point linear infinite;
  max-width: 10px;
}

.loading-points:before {
  animation-delay: 0s;
}

.loading-points span {
  animation-delay: .2s;
}

.loading-points:after {
  animation-delay: .4s;
}

@keyframes fade-point {
  50% {  opacity: 0  }
}

/* The typing effect */
@keyframes typing {
  from { width: 0; white-space: nowrap; }
  to { width: 100%; white-space: nowrap; }
}

@keyframes t{
  from {background-size:0 200%}
}
@keyframes b{
  50% {background-position:0 -100%,0 0}
}
