.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-chatbot-kit-chat-container {
  width: 100% !important;
  height: 95vh ;
}

.react-chatbot-kit-chat-header {
  background-color: #0E2E4E!important;
  font-size: 14px !important;
  color: #ffffff !important;
  padding: 12px!important;
}

.react-chatbot-kit-chat-message-container{
  height: auto !important;
  overflow: auto !important;
  max-height: calc(100vh - 240px);
  background-color: #0E2E4E !important;
}

.react-chatbot-kit-chat-inner-container{
  background-color: #0E2E4E !important;
}


/*.react-chatbot-kit-chat-bot-message {*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  line-height: 20px;*/
/*  max-width: 100%;*/
/*  width: fit-content!important;*/
/*  margin-left: 0 !important;*/
/*  background: transparent !important;*/
/*  color: white !important;*/
/*  font-size: 14px !important;*/
/*  padding: 4px 10px !important;*/
/*}*/

.react-chatbot-kit-chat-bot-message {
  border-radius: 13px !important;
  border-top-left-radius: 0 !important;
  background-color: #0E2E4E !important;
  color: white !important;
  font-size: 14px !important;
  padding: 4px 10px !important;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  min-height: 28px;
  width: 85%!important;
}


.react-chatbot-kit-chat-bot-message-arrow {
  display: none;
  border-top: 0 solid transparent!important;
  border-bottom: 14px solid transparent!important;
  border-right: 14px solid #ffffff!important;
  left: -11px!important;
  top: 0!important;
}

.react-chatbot-kit-user-chat-message-arrow {
  border-top: 0 solid transparent!important;
  border-bottom: 14px solid transparent!important;
  border-left: 14px solid #e8ffde!important;
  right: -11px!important;
  top: 0!important;
  display: none;
}

.react-chatbot-kit-user-chat-message {
  border-radius: 13px !important;
  border-top-right-radius: 0 !important;
  background-color: transparent!important;
  color: white !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 4px 10px !important;
}

.react-chatbot-kit-user-chat-message-container .react-chatbot-kit-user-avatar {
  display: none;
}


.react-chatbot-kit-chat-bot-message span {
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
  animation: typing 1.8s steps(50, end);
}

.react-chatbot-kit-chat-input-form {
  margin: auto;
  width: 85% !important;
  background-color: transparent;
  padding: 10px;
  border: 1px solid #e4e4e7;
  border-radius: 12px;
}

.react-chatbot-kit-chat-input {
  border-top: initial!important;
  outline: none;
  font-size: 14px !important;
  padding: 8px 0 !important;
  background: transparent;
  color: white;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #b5b5b5 !important;
}


.react-chatbot-kit-chat-btn-send {
  background-color: transparent !important;
  box-shadow: 0 0 !important;
  width: 35px !important;
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-chatbot-kit-chat-btn-send svg {
  fill: #b5b5b5 !important;
  width: 16px;
}

.chatId {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  color: #a3a3a3;
  font-size: 10px;
  width: fit-content;
}